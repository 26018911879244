import React from "react";
import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core/styles";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Img from "gatsby-image/withIEPolyfill";
import classes from "../styles/covid.module.css";

const title = "COVID-19 Protocol | Avenue Barber";
const description = "How we are helping to keep you safe.";

export default function Home({ data, location }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        {/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}

        {/* <meta property="og:url" content="PERMALINK" /> */}

        <meta property="og:site_name" content="Avenue Barber" />
        <link rel="canonical" href="https://avenuebarber.ca/covid" />
      </Helmet>
      <StylesProvider injectFirst>
        <Layout data={data} location={location}>
          <div className={classes.text}>
            <div
              style={{
                backgroundColor: "#ffeeec",
                padding: 16,
                borderRadius: 3,
                fontWeight: 500,
                color: "rgba(0,0,0,.7)",
                marginBottom: "1em",
              }}
            >
              <p>
                <b className={classes.ownerNote}>Note from Michael:</b> As of
                May 2022, our COVID-19 protocols have been relaxed for the time
                being. You may wear a mask if you wish, and if you would like
                our staff to wear one while cutting your hair please ask. Thank
                you for your continued support.
              </p>
              <p> You can still view our old policies below if you wish.</p>
            </div>
          </div>
          <div className={classes.imgContainer}>
            <Img
              alt="Photo of Michael and Angela"
              fluid={data.coverImage.childImageSharp.fluid}
              className={classes.img}
            />
          </div>
          <div className={classes.text}>
            <p>
              We are asking that you be patient with us in these COVID times.
              Things will take a bit longer. We are committed to keeping our
              shop clean. We will be vigilant in making sure the environment is
              the safest and most sanitary it can be.
            </p>
            <h3>How we are helping to keep you safe:</h3>
            <ul>
              <li>
                Staff will be screened for symptoms and have their temperatures
                taken at the start of each shift.
              </li>
              <li>
                We will also be taking customers’ temperatures when they arrive.
                If your temperature is high we will ask you to reschedule
              </li>
              <li>
                We are committed to disinfecting tools, chairs and stations
                between each client.
              </li>
              <li>
                We will sanitize our hands and wear face masks for every client.
              </li>
              <li>
                Clean towels, neck strips and disinfected capes will be provided
                for each client.
              </li>
              <li>
                We have 2 Air Purifiers that feature a HEPA filter, Charcoal
                Filter and UV-C light technology that works with Titanium
                Dioxide to reduce airborne bacteria, viruses, germs and mold
                spores.
              </li>
              <li>Our barber chairs are spaced 6 feet apart.</li>
              <li>
                As recommended, we will only have a few chairs, appropriately
                spaced, in the waiting area. If the waiting area is full we ask
                that you wait outside until we are ready for you.
              </li>
              <li>
                We won’t be providing any magazines or books for you to enjoy at
                this time.
              </li>
              <li>
                Our bathroom will only be available if absolutely necessary.
              </li>
              <li>
                We will be sanitizing the shop regularly throughout the day
              </li>
              <li>
                We will be taking first names and phone numbers each day for
                contact tracing purposes only.
              </li>
            </ul>
            <h3>How you can help us keep everyone safe:</h3>
            <ul>
              <li>Socially distance from others.</li>
              <li>
                Wear a face mask (required). If you do not bring one we will
                provide one for you at a cost of $2.
              </li>
              <li>
                Sanitize your hands immediately upon entering the shop. There
                will be hand sanitizer appropriately placed throughout the shop
                if you need more.
              </li>
              <li>
                If you have a fever or are feeling unwell, please reschedule.
              </li>
              <li>
                If you have an appointment, please arrive as close to that time
                as possible. If you are a walk-in customer, please check in when
                you arrive and we will do our best to accommodate you or give
                you an approximate time to return.
              </li>
            </ul>
          </div>
        </Layout>
      </StylesProvider>
    </>
  );
}

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "covid.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
